import getCsrfToken from 'modules/request/get-csrf-token'
import { useEffectEvent } from 'modules/util/react'
import Pusher from 'pusher-js'
import { useEffect } from 'react'

let pusher
let privateChannel

const initializePusher = () => {
  if (pusher) return

  if (window.pusher) {
    pusher = window.pusher
    privateChannel = window.privateChannel
  } else {
    pusher = new Pusher(window.pusherKey, {
      channelAuthorization: { headers: { 'X-CSRF-Token': getCsrfToken() } },
      cluster: window.pusherCluster,
    })

    privateChannel = pusher.subscribe(`private-cliniko-${window.account_id}`)

    window.pusher = pusher
    window.privateChannel = privateChannel
  }
}

export const useAny = (resourceName, callback, options) => {
  useCreated(resourceName, callback, options)
  useDeleted(resourceName, callback, options)
  useUpdated(resourceName, callback, options)
}

export const useCreated = (resourceName, callback, options) => {
  useEventHandler(`${resourceName}:created`, callback, options)
}

export const useDeleted = (resourceName, callback, options) => {
  useEventHandler(`${resourceName}:deleted`, callback, options)
}

export const useUpdated = (resourceName, callback, options) => {
  useEventHandler(`${resourceName}:updated`, callback, options)
}

export const useEventHandler = (eventName, callbackIn, { when = true } = {}) => {
  initializePusher()
  const callback = useEffectEvent(callbackIn)

  useEffect(() => {
    if (!when) return
    privateChannel.bind(eventName, callback)
    return () => privateChannel.unbind(eventName, callback)
  }, [callback, eventName, when])
}
