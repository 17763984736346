import * as _ from 'modules/util'
import * as L from 'partial.lenses'
import * as R from 'ramda'

const binaryOperators = {
  contains: '~',
  find: '~~',
  gte: '>=',
  gt: '>',
  is: '=',
  isnt: '!=',
  lte: '<=',
  lt: '<',
}

const unaryOperators = {
  isSet: '?',
  isntSet: '!?',
}

const operators = R.mergeRight(binaryOperators, unaryOperators)

export const formatFilterParams = params => {
  if (!params) return

  const addDefaultOperator = R.when(
    R.anyPass([R.is(String), R.is(Number), R.is(Array)]),
    R.objOf('is')
  )

  const cleanArrayValues = L.modify('value', R.when(R.is(Array), R.pipe(R.uniq, R.reject(R.isNil))))

  const collectAsOperatorAndValue = L.collectAs(
    ([operator, value]) =>
      R.includes(operator, R.values(unaryOperators)) ? { operator } : { operator, value },
    [L.keyed, L.elems]
  )

  const mapToQueryStrings = ({ column, operator, value = '' }) =>
    `${_.snakeCase(column)}:${operator}${value}`

  const negateIsSetOperator = R.when(R.propEq(false, '?'), R.always({ '!?': true }))

  const negateIsntSetOperator = R.when(R.propEq(false, '!?'), R.always({ '?': true }))

  const transformParams = ([column, value]) =>
    _.thru(
      value,
      addDefaultOperator,
      unaliasOperator,
      negateIsSetOperator,
      negateIsntSetOperator,
      collectAsOperatorAndValue,
      R.map(
        R.pipe(R.mergeRight({ column }), cleanArrayValues, validateOperators, mapToQueryStrings)
      )
    )

  const unaliasOperator = L.modify(L.keys, key => R.prop(key, operators) || key)

  const validateOperators = R.tap(
    R.pipe(
      R.prop('operator'),
      R.unless(R.includes(R.__, R.values(operators)), operator => {
        throw new Error(`invalid operator, ${operator}`)
      })
    )
  )

  return _.thru(params, L.collect([L.keyed, L.elems]), R.map(transformParams), R.flatten)
}
