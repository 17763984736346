import * as D from 'modules/document'
import * as _ from 'modules/util'
import { useEffectEvent } from 'modules/util/react'
import * as R from 'ramda'
import { useState } from 'react'
import { FlashMessagesContext } from './context'
import { eventNames } from './events'
import { makeAddMessage } from './messages'
import { typeIncludedIn } from './util'

export const Provider = ({ children, messages: initialMessages = {}, timeout }) => {
  const [messages, setMessages] = useState([])
  const addMessage = useEffectEvent(makeAddMessage(setMessages))

  _.useEffectOnce(() => {
    Object.entries(initialMessages).forEach(([type, message]) => {
      message && addMessage({ message, type, timeout })
    })
  })

  D.useEventListener(eventNames.add, ({ detail: props }) => addMessage(props))

  D.useEventListener(eventNames.clear, ({ detail: types }) =>
    setMessages(R.reject(typeIncludedIn(types)))
  )

  return (
    <FlashMessagesContext.Provider value={{ messages, setMessages }}>
      {children}
    </FlashMessagesContext.Provider>
  )
}
