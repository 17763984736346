import * as _ from 'modules/util'
import * as L from 'partial.lenses'
import * as R from 'ramda'

const appendBracketsToEntryKey = L.modify(0, key => R.concat(key, '[]'))
const entryValueIsAnArray = ([, value]) => _.isArray(value)

export const objectToQueryString = R.pipe(
  L.modify([L.entries, L.when(entryValueIsAnArray)], appendBracketsToEntryKey),
  L.getInverse([L.querystring])
)

const castEntryValueToArray = L.modify(1, _.castArray)
const entryKeyHasBrackets = ([key]) => R.endsWith('[]', key)
const removeBracketsFromEntryKey = L.modify(0, R.replace('[]', ''))

export const queryStringToObject = R.pipe(
  L.get(L.querystring),
  L.remove([L.keys, L.when(_.isBlank)]),
  L.modify(
    [L.entries, L.when(entryKeyHasBrackets)],
    R.pipe(removeBracketsFromEntryKey, castEntryValueToArray)
  )
)
