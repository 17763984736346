import { asAbsoluteUrl, findCollectionKey } from 'modules/request/util'
import * as _ from 'modules/util'
import * as R from 'ramda'

const normalizeUrl = url => (_.isTest() ? asAbsoluteUrl(url) : url)

export const cacheResourcesInCollection = (data, queryKey, queryClient) => {
  const collectionKey = findCollectionKey(data)
  if (!collectionKey) return

  const [{ headers: headersIn, ...queryKeyProps }] = queryKey
  const headers = R.omit(['X-Total-Entries'], headersIn)

  data[collectionKey]
    .filter(resource => resource?.links?.self)
    .forEach(resource => {
      const resourceQueryKey = [{ ...queryKeyProps, url: normalizeUrl(resource.links.self) }]
      if (!R.isEmpty(headers)) resourceQueryKey[0].headers = headers
      queryClient.setQueryData(resourceQueryKey, resource)
    })
}
