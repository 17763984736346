import * as R from 'ramda'
import { useSetMessages } from './messages'
import { typeIncludedIn } from './util'

export const useClear = () => {
  const setMessages = useSetMessages()
  const clear = (types = ['error', 'info', 'success', 'warning'], ...args) =>
    setMessages(R.reject(typeIncludedIn([].concat(types).concat(args))))

  return {
    clear,
    clearAll: () => clear('error', 'info', 'success', 'warning'),
    clearError: () => clear('error'),
    clearInfo: () => clear('info'),
    clearSuccess: () => clear('success'),
    clearWarning: () => clear('warning'),
  }
}
