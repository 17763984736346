import { useCallback, useEffect, useRef } from 'react'

export { useMergeRefs } from '@floating-ui/react'

export const useAutoFocus = (autoFocus = true) =>
  useCallback(
    node => {
      if (autoFocus && node) {
        node.setAttribute('data-focus-visible-added', true)
        node.focus()
      }
    },
    [autoFocus]
  )

export const useEffectOnce = callbackIn => {
  const callback = useRef(callbackIn)
  useEffect(() => callback.current(), [])
}

export const useUnchangingValue = (value, message) => {
  const valueRef = useRef(value)
  if (valueRef.current !== value) throw Error(message)
}
