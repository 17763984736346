const fetch = async ({ headers, method = 'GET', ...options }) => {
  const request = {
    credentials: 'same-origin',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json; charset=utf-8',
      ...headers,
    },
    method,
    timeout: 35 * 1000,
    ...options,
  }

  let response

  try {
    response = await window.fetch(request.url, { ...request, body: stringifyBody(request.body) })
  } catch (error) {
    throw new Error(error.message, { cause: { request } })
  }

  try {
    response = await parseResponse(response)
  } catch (error) {
    throw new Error(`Unable to parse response. ${error.message}`, { cause: { request, response } })
  }

  if (response.status < 400) {
    return { request, response }
  }

  throw new Error('fetch', { cause: { request, response } })
}

const parseBody = async response => {
  if (response.status === 204) return undefined

  try {
    return await response.json()
  } catch (error) {
    console.error(error)
  }
}

const parseResponse = async response => ({
  body: await parseBody(response),
  headers: Object.fromEntries(response.headers.entries()),
  status: response.status,
  url: response.url,
})

const stringifyBody = body => (typeof body !== 'string' ? JSON.stringify(body) : body)

export default fetch
