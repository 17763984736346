import * as L from 'partial.lenses'
import * as R from 'ramda'
import { pipe } from 'ramda'
import { isFunction } from './conditions'

export const actions = (...fnsIn) => {
  const fns = L.collect([L.flatten, L.when(isFunction)], fnsIn)
  return event => {
    for (let i = 0, n = fns.length; i < n; ++i) fns[i](event)
  }
}

export const applyTo =
  (...args) =>
  fn =>
    R.apply(fn, args)

export const applyViewTo = R.curry((data, view) =>
  thru(
    view,
    R.when(
      R.either(R.complement(isFunction), R.both(isFunction, R.pipe(R.length, R.equals(4)))),
      L.get
    ),
    R.applyTo(data)
  )
)

export const defineName = (name, fn) =>
  Object.defineProperty(fn, 'name', { name, configurable: true })

export const show = x => {
  console.log(x)
  return x
}

export const thru = (value, ...functions) => pipe(...functions)(value)
