import * as L from 'partial.lenses'
import * as R from 'ramda'
import { isArray, isBlank, isObject } from './conditions'
import { thru } from './functions'
import { snakeCamelKeysIso } from './lenses'

export const camelizeKeys = L.get(snakeCamelKeysIso)

export const removeBlankValues = L.remove([
  L.values,
  L.lazy(recurse =>
    L.cond(
      [isArray, [L.defaults([]), L.elems, recurse]],
      [isObject, [L.defaults({}), L.values, recurse]],
      [L.when(isBlank)]
    )
  ),
])

export const snakifyKeys = L.getInverse(snakeCamelKeysIso)

export const zipArrayToObject = propertyNames => R.zipObj(propertyNames, propertyNames)

export const omitDeep = R.curry((keys, object) =>
  thru(
    object,
    R.omit(keys),
    R.mapObjIndexed(value => {
      if (isObject(value)) return omitDeep(keys, value)
      if (isArray(value))
        return value.map(item => {
          if (isObject(item)) return omitDeep(keys, item)
          return item
        })
      return value
    })
  )
)

export const intersectDeepRight = R.curry((left, right) => {
  const intersectValues = (leftValue, rightValue) => {
    if (leftValue === undefined) return
    if ([leftValue, rightValue].every(isObject)) return intersectDeepRight(leftValue, rightValue)
    if ([leftValue, rightValue].every(isArray))
      return rightValue
        .map((r, index) => {
          const l = leftValue[index]
          return intersectValues(l, r)
        })
        .filter(x => x !== undefined)
    return rightValue
  }

  return thru(
    right,
    R.mapObjIndexed((rightValue, rightKey) => {
      const leftValue = left[rightKey]
      return intersectValues(leftValue, rightValue)
    }),
    R.pickBy(x => x !== undefined)
  )
})

export const concatByUniqueId = (record, records) =>
  R.uniqBy(
    record => record?.id,
    [record, ...records].filter(record => record?.id)
  )
