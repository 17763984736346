import _debounce from 'lodash/debounce'
import { useEffectEvent, useResolvedElement } from 'modules/util/react'
import * as R from 'ramda'
import { useCallback } from 'react'
import { actions } from './functions'

export const isFromInput = event =>
  ['INPUT', 'SELECT', 'TEXTAREA'].includes(event.target.nodeName) ||
  event.target.getAttribute?.('role') === 'combobox'

export const onKey = R.pipe(
  R.toPairs,
  R.map(([key, action]) => [R.whereEq({ key }), actions(e => e.preventDefault(), action)]),
  R.cond
)

export const useEventListener = (event, callbackIn, { debounce, ref: refIn, when = true } = {}) => {
  const stableCallback = useEffectEvent(callbackIn)

  const ref = useResolvedElement(
    useCallback(
      node => {
        if (!node || !when) return

        const callback = debounce ? _debounce(stableCallback, debounce) : stableCallback
        node.addEventListener(event, callback)
        return () => node.removeEventListener(event, callback)
      },
      [debounce, event, stableCallback, when]
    ),
    refIn
  )

  return ref
}
