import { useCallback, useEffect, useLayoutEffect, useRef } from 'react'

export const getCurrent = maybeRef =>
  maybeRef && Object.prototype.hasOwnProperty.call(maybeRef, 'current')
    ? maybeRef.current
    : maybeRef

// https://github.com/reactjs/rfcs/blob/useevent/text/0000-useevent.md
export const useEffectEvent = handler => {
  const handlerRef = useRef(null)

  // In a real implementation, this would run before layout effects
  useLayoutEffect(() => {
    handlerRef.current = handler
  })

  return useCallback((...args) => {
    // In a real implementation, this would throw if called during render
    const fn = handlerRef.current
    return fn(...args)
  }, [])
}

// This could've been more streamlined with internal state instead of abusing
// refs to such extent, but then composing hooks and components could not opt out of unnecessary renders.
export const useResolvedElement = (subscriber, refOrElement) => {
  const lastReportRef = useRef(null)
  const refOrElementRef = useRef(null)
  refOrElementRef.current = refOrElement
  const cbElementRef = useRef(null)

  // Calling re-evaluation after each render without using a dep array,
  // as the ref object's current value could've changed since the last render.
  useLayoutEffect(() => {
    evaluateSubscription()
  })

  const evaluateSubscription = useCallback(() => {
    const cbElement = cbElementRef.current
    const refOrElement = refOrElementRef.current

    const element =
      cbElement ||
      (refOrElement
        ? Object.prototype.hasOwnProperty.call(refOrElement, 'current')
          ? refOrElement.current
          : refOrElement
        : null)

    if (
      lastReportRef.current &&
      lastReportRef.current.element === element &&
      lastReportRef.current.subscriber === subscriber
    ) {
      return
    }

    if (lastReportRef.current?.cleanup) {
      lastReportRef.current.cleanup()
    }

    lastReportRef.current = {
      element,
      subscriber,
      // Only calling the subscriber, if there's an actual element to report.
      // Setting cleanup to undefined unless a subscriber returns one, as an existing cleanup function would've been just called.
      cleanup: element ? subscriber(element) : undefined,
    }
  }, [subscriber])

  // making sure we call the cleanup function on unmount
  useEffect(
    () => () => {
      if (lastReportRef.current?.cleanup) {
        lastReportRef.current.cleanup()
        lastReportRef.current = null
      }
    },
    []
  )

  return useCallback(
    element => {
      cbElementRef.current = element
      evaluateSubscription()
    },
    [evaluateSubscription]
  )
}
