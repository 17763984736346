import * as _ from 'modules/util'
import * as R from 'ramda'

export const useEventListener = (event, callback, options) =>
  _.useEventListener(event, callback, { ref: document, ...options })

export const useClick = (...args) => useEventListener('click', ...args)
export const useKeyDown = (...args) => useEventListener('keydown', ...args)
export const useKeyPress = (...args) => useEventListener('keypress', ...args)
export const useMouseDown = (...args) => useEventListener('mousedown', ...args)

export const useKeyDownMatching = (match, callback, options) => {
  useKeyDown(R.when(R.whereEq(match), callback), options)
}
