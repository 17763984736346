import * as _ from 'modules/util'
import * as R from 'ramda'
import { useContext, useMemo } from 'react'
import { FlashMessagesContext } from './context'
import { typeIncludedIn } from './util'

export const makeAddMessage = setMessages => {
  const formatMessage = ({
    action,
    dismissible = true,
    htmlSafe,
    icon,
    id = _.randomId(),
    message,
    sanitize = htmlSafe,
    timeout = 4000,
    type,
  }) => {
    if (!message) return

    return {
      action,
      config: _.when(type === 'error', { bounce: 0.6, tension: 125, friction: 14 }),
      dismissible,
      id,
      icon,
      message,
      sanitize,
      timeout,
      type,
    }
  }

  return R.ifElse(
    _.isFunction,
    setMessages,
    R.pipe(
      formatMessage,
      R.unless(
        R.isNil,
        R.pipe(R.ifElse(R.propEq('error', 'type'), R.prepend, R.append), setMessages)
      )
    )
  )
}

export const useMessages = (types = ['error', 'info', 'success', 'warning'], ...args) => {
  const messages = useContext(FlashMessagesContext).messages || []
  return messages.filter(typeIncludedIn([].concat(types).concat(args)))
}

export const useSetMessages = () => {
  const { setMessages } = useContext(FlashMessagesContext)
  return useMemo(() => makeAddMessage(setMessages), [setMessages])
}
