export const eventNames = {
  add: 'add-flash-message',
  clear: 'clear-flash-messages',
}

export const clear = (types = ['error', 'info', 'success', 'warning'], ...args) => {
  document.dispatchEvent(
    new window.CustomEvent(eventNames.clear, { detail: [].concat(types).concat(args) })
  )
}

const flashMessageWithType =
  type =>
  (
    message,
    { action, dismissible = true, htmlSafe, icon, sanitize = htmlSafe, timeout = 4000 } = {}
  ) =>
    document.dispatchEvent(
      new window.CustomEvent(eventNames.add, {
        detail: { action, dismissible, htmlSafe, icon, message, sanitize, timeout, type },
      })
    )

export const error = flashMessageWithType('error')
export const info = flashMessageWithType('info')
export const success = flashMessageWithType('success')
export const warning = flashMessageWithType('warning')
