import { cacheResourcesInCollection } from 'modules/request/cache'
import { fetchAllPages } from 'modules/request/fetch-all-pages'
import { performRequest } from 'modules/request/perform-request'
import { loadRelationships } from 'modules/request/related'
import { normalizeRequestOptions } from 'modules/request/util'
import * as _ from 'modules/util'

const fetchWithRelated = ({ queryKey: [{ related, ...queryKeyProps }], ...options }, queryClient) =>
  queryClient.fetchQuery({ ...options, queryKey: [queryKeyProps] }).then(data =>
    loadRelationships(data, related, {
      requestFn: (...args) => queryClient.fetchQuery(queryOptions(...args)),
    })
  )

const generateQueryKey = options => [
  Object.fromEntries(
    Object.entries(normalizeRequestOptions(options)).filter(
      ([key, value]) => value && ['allPages', 'headers', 'related', 'url'].includes(key)
    )
  ),
]

export const queryOptions = ({
  allPages,
  headers,
  params,
  related,
  url,
  useReplica,
  withTotalEntries,
  ...optionsIn
}) => {
  const queryFn = ({ meta: { queryClient }, queryKey }) =>
    queryKey[0].related
      ? fetchWithRelated({ ...options, queryKey }, queryClient)
      : performQuery({ ...options, queryKey }, queryClient)

  const queryKey = generateQueryKey({
    allPages,
    headers,
    params,
    related,
    url,
    useReplica,
    withTotalEntries,
  })

  const options = {
    queryFn,
    queryKey,
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
    retry: false,
    staleTime: 5000,
    throwOnError: true,
    ...optionsIn,
  }

  return options
}

const performQuery = async (
  { cacheResourcesInCollection: shouldCacheResources, queryKey: [{ allPages, ...queryKeyProps }] },
  queryClient
) => {
  const getFn = allPages ? fetchAllPages : performRequest
  const data = await getFn(queryKeyProps)
  if (shouldCacheResources) cacheResourcesInCollection(data, [queryKeyProps], queryClient)
  return data
}
