import getCsrfToken from 'modules/request/get-csrf-token'
import { performRequest } from './perform-request'

export const deleteOptions = options => mutationOptions({ ...options, method: 'DELETE' })

export const mutationOptions = ({ body, bodyOnly, headers, method, url, ...options } = {}) => {
  const mutationFn = variables =>
    performRequest({
      body,
      bodyOnly,
      headers: {
        'X-CSRF-Token': getCsrfToken(),
        ...headers,
      },
      method,
      url,
      ...variables,
    })

  return { mutationFn, ...options }
}

export const patchOptions = options => mutationOptions({ ...options, method: 'PATCH' })

export const postOptions = options => mutationOptions({ ...options, method: 'POST' })

export const putOptions = options => mutationOptions({ ...options, method: 'PUT' })
