import { useAny, useCreated, useDeleted, useUpdated } from 'modules/pusher'
import * as Request from 'modules/request'
import * as _ from 'modules/util'

export const makeResource = (name, { endpoint: endpointIn, watchName: watchNameIn } = {}) => {
  const endpoint = endpointIn || `/v1/${_.snakeCase(name)}`

  const addUrlToOptionsAndRemoveId = ({ id, ...options } = {}) => ({
    url: id ? `${endpoint}/${id}` : endpoint,
    ...options,
  })

  const archiveOptions = optionsIn => {
    const options = addUrlToOptionsAndRemoveId(optionsIn)
    return postOptions({ ...options, url: `${options.url}/archive` })
  }

  const deleteOptions = options => Request.deleteOptions(addUrlToOptionsAndRemoveId(options))

  const patchOptions = options => Request.patchOptions(addUrlToOptionsAndRemoveId(options))

  const postOptions = options => Request.postOptions(addUrlToOptionsAndRemoveId(options))

  const putOptions = options => Request.putOptions(addUrlToOptionsAndRemoveId(options))

  const queryOptions = options => Request.queryOptions(addUrlToOptionsAndRemoveId(options))

  const saveOptions = (options = {}) => (options.id ? patchOptions(options) : postOptions(options))

  const unarchiveOptions = optionsIn => {
    const options = addUrlToOptionsAndRemoveId(optionsIn)
    return postOptions({ ...options, url: `${options.url}/unarchive` })
  }

  const useInvalidateCache = options =>
    Request.useInvalidateCache(addUrlToOptionsAndRemoveId(options))

  const watchName = _.snakeCase(watchNameIn || _.singularize(name))
  const watch = {
    useAny: callback => useAny(watchName, callback),
    useCreated: callback => useCreated(watchName, callback),
    useDeleted: callback => useDeleted(watchName, callback),
    useUpdated: callback => useUpdated(watchName, callback),
  }

  return {
    archiveOptions,
    deleteOptions,
    patchOptions,
    postOptions,
    putOptions,
    queryOptions,
    saveOptions,
    unarchiveOptions,
    useInvalidateCache,
    watch,
  }
}
