import { performRequest } from 'modules/request/perform-request'
import {
  asAbsoluteUrl,
  findCollectionKey,
  joinUrlWithParams,
  unwrapCollection,
} from 'modules/request/util'
import * as _ from 'modules/util'
import * as R from 'ramda'

const combineCollections = ([firstPage, ...otherPages]) => {
  const collection = [firstPage, ...otherPages].map(unwrapCollection).flat()
  const collectionKey = findCollectionKey(firstPage)
  return {
    ...firstPage,
    [collectionKey]: collection,
    links: { self: removePageParam(firstPage.links.self) },
  }
}

export const fetchAllPages = async options => {
  throwWhenOptionsAreInvalid(options)

  const firstPage = await performRequest({
    ...options,
    headers: {
      ...options.headers,
      'X-Total-Entries': true,
    },
  })
  if (!firstPage.links.next) return combineCollections([firstPage])

  const numberOfPages = Math.ceil(firstPage.totalEntries / 250)
  const otherPages = await Promise.all(
    R.range(2, numberOfPages + 1).map(pageNumber =>
      performRequest({
        ...options,
        url: joinUrlWithParams(firstPage.links.next, { page: pageNumber }),
      })
    )
  )

  return combineCollections([firstPage, ...otherPages])
}

const removePageParam = url => url.replace(/&?page=\d+/, '').replace(/\?$/, '')

const throwWhenOptionsAreInvalid = ({ headers, url }) => {
  _.throwWhen(
    headers?.['X-Total-Entries'] !== undefined,
    'X-Total-Entries header must not be given when loading all pages'
  )

  _.throwWhen(
    urlHasQueryParam(url, 'page'),
    'The page query param must not be given when loading all pages'
  )

  _.throwWhen(
    urlHasQueryParam(url, 'per_page'),
    'The per_page query param must not be given when loading all pages'
  )
}

const urlHasQueryParam = (url, paramName) => new URL(asAbsoluteUrl(url)).searchParams.has(paramName)
