let csrfToken

const getCsrfToken = () => {
  if (!csrfToken) {
    const csrfMetaElement = document.querySelector('meta[name="csrf-token"]')
    csrfToken = csrfMetaElement && csrfMetaElement.getAttribute('content')
  }

  return csrfToken
}

export default getCsrfToken
