import { findCollectionKey, unwrapCollection } from 'modules/request/util'
import * as _ from 'modules/util'
import * as R from 'ramda'

export const loadRelationships = (data, related, { requestFn } = {}) => {
  const getAllPages = async options => {
    const firstPage = await requestFn(options)
    if (!findCollectionKey(firstPage)) return firstPage
    if (!firstPage.links.next) return unwrapCollection(firstPage)

    const { totalEntries, ...secondPage } = await requestFn({
      ...options,
      params: {},
      url: firstPage.links.next,
      withTotalEntries: true,
    })

    const numberOfPages = Math.ceil(totalEntries / 250)
    const pages = await Promise.all(
      R.range(3, numberOfPages + 1).map(pageNumber =>
        requestFn({ ...options, params: { page: pageNumber }, url: firstPage.links.next })
      )
    )
    return [firstPage, secondPage, ...pages].map(unwrapCollection).flat()
  }

  const getRelatedDatasForCollection = (collection, relationshipName, options) => {
    if (collection.some(relatedResourceId(relationshipName))) {
      return getRelatedResourcesForCollectionEfficiently(collection, relationshipName, options)
    } else if (collection.some(relatedCollectionBelongsToFilterName(relationshipName))) {
      return getRelatedCollectionsForCollectionEfficiently(collection, relationshipName, options)
    } else {
      return Promise.all(
        collection.map(resource => getRelatedDataForResource(resource, relationshipName, options))
      )
    }
  }

  const getRelatedCollectionsForCollectionEfficiently = async (
    collection,
    relationshipName,
    { related, ...options }
  ) => {
    const sampleResource = collection.find(resource => resource[relationshipName])
    const url = sampleResource[relationshipName].links.self.replace(
      relatedCollectionBelongsToFilterRegex,
      ''
    )
    const filterName = relatedCollectionBelongsToFilterName(relationshipName, sampleResource)
    const filterIds = collection.filter(resource => resource[relationshipName]).map(({ id }) => id)

    const requests = R.splitEvery(100, filterIds).map(ids =>
      getAllPages({ url, ...R.mergeDeepRight({ params: { q: { [filterName]: ids } } }, options) })
    )

    const responses = await Promise.all(requests)
    const relatedResources = await loadRelationships(responses.flat(), related, { requestFn })

    const belongsToRelationshipName = filterName.replace(/Id$/, '')
    return collection.map(resource => {
      if (!resource[relationshipName]) return undefined

      return relatedResources.filter(
        relatedResource =>
          relatedResource[belongsToRelationshipName]?.links?.self === resource.links.self
      )
    })
  }

  const getRelatedResourcesForCollectionEfficiently = async (
    collection,
    relationshipName,
    { related, ...options }
  ) => {
    const sampleResource = collection.find(resource => resource[relationshipName])
    const url = sampleResource[relationshipName].links.self.replace(trailingIdRegex, '')
    const relatedResourceIds = R.uniq(
      collection.map(relatedResourceId(relationshipName)).filter(Boolean)
    )

    const requests = R.splitEvery(100, relatedResourceIds).map(ids =>
      requestFn({ url, ...R.mergeDeepRight({ params: { q: { id: ids } } }, options) }).then(
        unwrapCollection
      )
    )

    const responses = await Promise.all(requests)
    const relatedResources = await loadRelationships(responses.flat(), related, { requestFn })

    return collection.map(resource => {
      if (!resource[relationshipName]) return undefined

      return relatedResources.find(
        relatedResource => relatedResource?.links?.self === resource[relationshipName].links.self
      )
    })
  }

  const getRelatedDataForResource = async (resource, relationshipName, { related, ...options }) => {
    const url = resource[relationshipName]?.links?.self
    if (!url) return
    const relatedData = await getUrl(url, options)
    return loadRelationships(relatedData, related, { requestFn })
  }

  const getRelationshipForCollection = async (collection, relationshipName, options) => {
    const relatedDatas = await getRelatedDatasForCollection(collection, relationshipName, options)
    return relatedDatas.map(relatedData => ({ [relationshipName]: relatedData }))
  }

  const getRelationshipForResource = async (resource, relationshipName, options) => {
    const relatedData = await getRelatedDataForResource(resource, relationshipName, options)
    return { [relationshipName]: relatedData }
  }

  const getRelationshipsForResource = (resource, related) => {
    const requests = Object.entries(related).map(([relationshipName, options]) =>
      getRelationshipForResource(resource, relationshipName, options)
    )
    return Promise.all(requests).then(R.mergeAll)
  }

  const getUrl = (url, options) =>
    getAllPages({ url, ...options }).catch(error => {
      if (error.cause?.response?.status !== 404) throw error
    })

  const loadRelationshipsForCollection = async (collection, related) => {
    const requests = Object.entries(related).map(([relationshipName, options]) =>
      getRelationshipForCollection(collection, relationshipName, options)
    )
    const relationshipsMatrix = await Promise.all(requests)
    return collection.map((resource, index) => {
      const relationships = R.mergeAll(relationshipsMatrix.map(R.nth(index)))
      return { ...resource, ...relationships }
    })
  }

  const loadRelationshipsForWrappedCollection = async (wrappedCollection, related) => {
    const collectionKey = findCollectionKey(wrappedCollection)
    const data = await loadRelationshipsForCollection(wrappedCollection[collectionKey], related)
    return { ...wrappedCollection, [collectionKey]: data }
  }

  const loadRelationshipsForResource = async (resource, related) => {
    const relationships = await getRelationshipsForResource(resource, related)
    return { ...resource, ...relationships }
  }

  if (!related) {
    return data
  } else if (findCollectionKey(data)) {
    return loadRelationshipsForWrappedCollection(data, related)
  } else if (_.isArray(data)) {
    return loadRelationshipsForCollection(data, related)
  } else {
    return loadRelationshipsForResource(data, related)
  }
}

const relatedCollectionBelongsToFilterName = R.curry((relationshipName, resource) => {
  const filterName = resource[relationshipName]?.links?.self?.match(
    relatedCollectionBelongsToFilterRegex
  )?.groups?.filterName
  return filterName ? _.camelCase(filterName) : undefined
})

const relatedCollectionBelongsToFilterRegex =
  /&?q(?:%5B|\[)(?:%5D|\])=(?<filterName>[a-z_]+)(?:%3A|:)(?:%3D|=)(?<id>\d+)/

export const relatedResourceId = R.curry((relationshipName, resource) =>
  resource?.[relationshipName]?.links?.self?.match(trailingIdRegex)?.at(-1)
)

const trailingIdRegex = /\/(\d+)$/
